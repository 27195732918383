import React, { useEffect, useState } from "react";
import './Menu.css';
import * as FaIcons from 'react-icons/fa';
import { Link } from "react-router-dom";
import { baseUrl } from "../BaseUrl";

function MenuItems(props) {

    const [menu, setMenu] = React.useState([]);
    const [query, setQuery] = React.useState("");
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        // try {

        // for (let i = 0; i < props.menu.length; i++) {
        //     if (props.menu[i].id == props.menuId) {
        //         for (let j = 0; j < props.menu[i].sub_category.length; j++) {
        //             if (props.menu[i].sub_category[j].id == props.catId) {
        //                 setMenu(props.menu[i].sub_category[j].menu_item);
        //                 console.log(props.menu[i].sub_category[j].menu_item);
        //             }
        //         }
        //     }
        // }
        // }
        // catch (e) { fetchMenu(); }
        // setIsLoading(false);

        fetchMenu();

    }, []);


    const fetchMenu = async () => {
        await fetch(baseUrl + "/getSpecificMenu.php", {
            body: JSON.stringify({
                "catId": props.catId,
            }),
            method: "POST",
        })
            .then(res => res.json())
            .then(res => {
                setMenu(res); setIsLoading(false); console.log(res);
            })
            .catch((e) => console.log(e.message));
    }


    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="head-banner">
                <img className="banner-img" src="https://namastehotel.techeverestnepal.com/wp-content/uploads/2021/11/IMG_4789.png" />

                <p className="head-title">{decodeURIComponent(props.name)}</p>

                <div onClick={() => { window.history.back(); }} className="back-btn d-flex justify-content-center align-items-center" style={{ height: 30, width: 30, borderRadius: "50%", background: "#00000070", cursor: "pointer" }}>
                    <FaIcons.FaArrowLeft className="head-icon" color="#fff" />
                </div>
            </div>

            <div className="container mt-3 mt-md-5">
                <div className="mb-2">
                    <input value={query} onChange={(e) => { setQuery(e.target.value); }} className="form-control" placeholder={`Search for ${decodeURIComponent(props.name)} . . . .`} />
                </div>
                <div >
                    <div className="d-flex flex-wrap col-12">
                        {isLoading ? <div className="d-flex flex-wrap justify-content-center">
                            <div class="spinner-grow text-primary" role="status">
                            </div>
                            <div><h3>Loading...</h3></div>
                        </div> : <>{menu.length > 0 ? <>
                            {[...query.length > 0 ? menu.filter((e) => e.name.toLowerCase().includes(query.toLowerCase())) : menu].map((item, index) =>
                                <div key={index} className="d-flex flex-wrap col-12 col-md-6 p-2">
                                    <div className="col-12 d-flex px-1" style={{ background: "#a8a8a840", borderRadius: "10px" }}>
                                        <div className="my-auto">
                                            <img className="my-auto menu-img" style={{ height: item.price.length > 3 ? "150px" : "100px", width: item.price.length > 3 ? "110px" : "100px", }} src={baseUrl + item.image} />
                                        </div>
                                        <div className="ms-2 ms-md-3">
                                            <span className="menu-name">{item.name}</span><br />
                                            <span className="menu-desc">{item.description}</span>
                                            <div className="d-flex flex-wrap">
                                                {item.price.map((price, index) =>
                                                    <div key={index} className="pe-2 me-2" style={{ borderRight: "1px solid #00000050" }}>
                                                        <span className="menu-tag" style={{ fontSize: item.price.length > 3 ? '10px' : "13px" }}>{price.tag === "" ? 'Price' : price.tag}</span><br />
                                                        <span className="menu-price" style={{ fontSize: item.price.length > 3 ? '10px' : "13px" }}>Rs. {parseInt(price.price)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </> :
                            <div className="px-0">
                                <p>No dishes found!</p>
                            </div>
                        }</>}
                    </div>
                </div>

            </div>
        </div>
    );
}
export default MenuItems;
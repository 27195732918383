import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as FaIcons from 'react-icons/fa';
import { Link } from "react-router-dom";
import { baseUrl } from "../BaseUrl";

function Offers(props) {
    const [offers, setOffers] = useState([]);
    const [offer, setOffer] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchOffers();
    }, [])

    const fetchOffers = async () => {
        await fetch(baseUrl + "offers.php")
            .then(response => response.json())
            .then(data => {
                setOffers(data);
                setIsLoading(false);

            })
            .catch(error => {
                console.log(error.message);
                setIsLoading(false);
            });
    }
    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="head-banner">
                <img className="banner-img" src="https://namastehotel.techeverestnepal.com/wp-content/uploads/2021/11/IMG_4789.png" />

                <p className="head-title">Our Offers</p>

                <div onClick={() => { window.history.back(); }} className="back-btn d-flex justify-content-center align-items-center" style={{ height: 30, width: 30, borderRadius: "50%", background: "#00000070", cursor: "pointer" }}>
                    <FaIcons.FaArrowLeft className="head-icon" color="#fff" />
                </div>
            </div>
            <div className="col-12 d-flex flex-wrap mt-3 container">
                {isLoading ? <div className="d-flex flex-wrap justify-content-center">
                    <div class="spinner-grow text-primary" role="status">
                    </div>
                    <div><h3>Loading...</h3></div>
                </div> : <>
                    {offers.length > 0 ?
                        <>
                            {offers.map((offer, index) => {
                                return (
                                    <>
                                        <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 px-0 mt-2">
                                            <div className="card mx-1" style={{ height: "100%" }}>
                                                <img style={{ height: "350px", objectFit: "cover" }} src={baseUrl + offer.image} className="card-img-top" alt={offer.name} />
                                                <div className="card-body d-flex flex-column justify-content-between">
                                                    <h5 className="card-title">{offer.name}</h5>
                                                    <p className="card-text">{offer.description.length > 100 ? `${offer.description.substring(0, 100)}. . .}` : offer.description}</p>
                                                    <button onClick={() => { setOffer(offer); setIsModalOpen(true); }} className="btn btn-primary">View</button>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                );
                            })}
                        </> : <>
                            <h3>No Offers for now!</h3>
                        </>}</>}

            </div>
            <Modal
                show={isModalOpen}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                fullscreen={'md-down'}
            >

                <Modal.Body>
                    <div className="col-12 d-flex justify-content-end">
                        <FaIcons.FaTimes style={{ cursor: "pointer" }} size={25} onClick={() => { setIsModalOpen(false); }} className="head-icon mb-2" color="#000" />
                    </div>
                    <div className="card mx-1" style={{ height: "100%" }}>
                        <img src={baseUrl + offer.image} className="card-img-top" alt={offer.name} />
                        <div className="card-body">
                            <h5 className="card-title">{offer.name}</h5>
                            <p className="card-text">{offer.description}</p>
                            {/* <button onClick={() => { setOffer(offer); isModalOpen(true); }} className="btn btn-primary">View</button> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    );
}
export default Offers;
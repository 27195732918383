import React, { useEffect } from "react";
import './Menu.css';
import * as FaIcons from 'react-icons/fa';
import { Link } from "react-router-dom";
import { baseUrl } from "../BaseUrl";
import foodImage from "../../food.jpg";

function Menu(props) {

    useEffect(() => {

    }, []);

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="head-banner">
                <img className="banner-img" src={foodImage}/>

                <p className="head-title">Our Menu</p>

                <div onClick={() => { window.history.back(); }} className="back-btn d-flex justify-content-center align-items-center" style={{ height: 30, width: 30, borderRadius: "50%", background: "#00000070", cursor: "pointer" }}>
                    <FaIcons.FaArrowLeft className="head-icon" color="#fff" />
                </div>
            </div>

            <div className="container mt-3 mt-md-5">
                {props.menu.map((item, index) =>
                    <div key={index}>
                        <p className="fs-2 fw-bold">{item.name}</p>
                        <hr />
                        <div className="d-flex flex-wrap col-12">
                            {item.sub_category.map((subCat, index) =>
                                <Link key={index} to={`/menu-items/${encodeURIComponent(subCat.name)}/${item.id}/${subCat.id}`} className="text-black col-6 col-sm-4 col-md-3 col-lg-2 p-1 text-decoration-none">
                                    <div className="h-100 w-100">
                                        <img style={{ height: 130, width: "100%", objectFit: "cover" }} className="menu-img rounded shadow" src={baseUrl + subCat.image} />
                                        <div className="text-center">
                                            <p className="fw-bold" style={{ fontSize: 16 }}>{subCat.name}</p>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Menu;
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { baseUrl } from "./BaseUrl";
import Home from "./Home/Home";
import Menu from "./Menu/Menu";
import MenuItems from "./Menu/MenuItems";
import Offers from "./Offers/Offers";

function Main(props) {

    const [menuItems, setMenuItems] = React.useState([]);

    React.useEffect(() => {
        fetch(baseUrl + "categories.php")
            .then(response => response.json())
            .then(data => {
                setMenuItems(data);
                console.log(data);
            })
            .catch(error => { console.log(error.message) });
    }, []);

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/menu" component={() => <Menu menu={menuItems} />} />
                <Route exact path="/menu-items/:name/:menuId/:catId" component={({ match }) => {
                    // const menuId = match.params.menuId;
                    // const catId = match.params.catId;
                    // var arr = menuItems.filter(item => item.id == menuId).filter(item => item.sub_category.filter(subCat => subCat.id == catId));
                    return (
                        <MenuItems menu={menuItems} name={match.params.name} menuId={match.params.menuId} catId={match.params.catId} />
                    );
                }} />
                <Route exact path="/offers" component={() => <Offers />} />
                <Redirect to="/" />
            </Switch>
        </BrowserRouter>
    );
}
export default Main;
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.png";
import foodImage from "../../food.jpg";
import './Home.css';

function Home(props) {
    return (
        <>
            <section style={{ minHeight: "100vh" }}>
                <div className="head-banner">
                    <div className="logo-div">
                        <img className="w-100 h-100" style={{ objectFit: "contain" }} src={logo} />
                    </div>
                    <img className="banner-img" src={foodImage} />

                </div>
                <div >
                    <div className="hotel-name-section">
                        <div className="text-center">
                            <p className="head-name">Namaste Palpa Hotel and Restaurant</p>
                            <p className="hotel-loc">Rampur-5, Palpa</p>
                            {/* <figure class="text-end">
                        <blockquote class="blockquote">
                            <p>Enjoy our food and services!</p>
                        </blockquote>
                        <figcaption class="blockquote-footer">
                            <cite title="Source Title">Namaste Palpa Hotel & Restaurant</cite>
                        </figcaption>
                    </figure> */}
                        </div>
                    </div>
                    <div className="container mt-4 mt-md-5">
                        <div className="col-12 d-flex flex-wrap">
                            <Link to="/menu" className="col-12 col-md-6 p-2 text-decoration-none" style={{ cursor: "pointer" }}>
                                <div className="bg-success shadow rounded text-center py-3">
                                    <p className="my-auto text-white head-name ">View our Menu</p>
                                </div>
                            </Link>
                            <Link to={'/offers'} className="col-12 col-md-6 p-2 text-decoration-none" style={{ cursor: "pointer" }}>
                                <div className=" text-center bg-warning shadow rounded py-3">
                                    <p className="my-auto  head-name">View our Offers</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{ position: "fixed", right: "20px", bottom: "0px" }}>
                        <p className="small">Developed by <a target="_blank" href="https://techeverestnepal.com" className="fw-bold" style={{ cursor: "pointer" }}>Everest Technologies</a></p>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Home;
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './Components/Main';

function App() {
  return (
    <div className="body poppins-regular">
      <Main />
    </div>
  );
}

export default App;
